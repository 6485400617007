import React from 'react'
import ImageSection from './featureImageSection/ImageSection'
import UpoLite from './upoLite/UpoLite'
import UpoPro from './upoPro/UpoPro'

function Features() {
  return (
    <section id='features' style={{marginTop:'60px'}}>
        <UpoPro/>
        <UpoLite/>
    </section >
  )
}

export default Features