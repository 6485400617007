import React from 'react'
import About from '../../component/about/About'
import Banner from '../../component/banner/Banner'
import ContactInfo from '../../component/contactInfo/ContactInfo'
import Features from '../../component/features/Features'
import Support from '../../component/support/Support'
import Footer from '../../component/footer/Footer'
import './Home.css'
import Use from '../../component/use/Use'

function Home() {
  return (

    <div className='home'>
        <Banner />
        <Features />
        <About />
        <Use/>
        <Support />
        <ContactInfo />
        <Footer />
    </div >
  )
}

export default Home