import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import footerLogo from '../../assets/footerLogo.png'

function Footer() {
    return (
        <section id='footer'>
            <div className='footerContainer'>
                <div className='map'>
                    <div className='mapImg'>
                    </div>
                </div>
                <div className='footerLogoContainer'>
                    <div className='container footerLogo '>
                        <Link to='/' className='logo'>
                            <img src={footerLogo} height={60} />
                        </Link>
                        <h6>All rights reserved by upo-devices.com</h6>
                    </div>

                </div>
            </div>

        </section>

    )
}

export default Footer