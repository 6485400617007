import React from 'react'
import ImageSection from '../featureImageSection/ImageSection'
import upoPro from '../../../assets/upoPro.png'
import proIntegration from '../../../assets/proIntegration.png'
import proRemote from '../../../assets/proRemote.png'
import proReport from '../../../assets/proReport.png'
import proWifi from '../../../assets/proWifi.png'
import proBio from '../../../assets/proBio.png'
import proTrack from '../../../assets/proTrack.png'
import proStore from '../../../assets/proStorage.png'
import './UpoPro.css'

function UpoPro() {


    const data = [
        {
            image: proBio,
            featureName: "Biometric Authentication",
            description: "The device uses biometric authentication, such as fingerprint or facial recognition, to verify the identity of the person."
        },
        {
            image: proTrack,
            featureName: "Real-time Tracking",
            description: "The device can track attendance in real-time and provide live updates to a centralized system."
        },
        {
            image: proWifi,
            featureName: "Wireless Connectivity",
            description: "The device uses wireless connectivity, such as Wi-Fi or Bluetooth, to communicate with other devices and systems."
        },

        {
            image: proReport,
            featureName: "Analytics and Reporting",
            description: "The device can provide analytics and reports on attendance patterns, helping organizations make data-driven decisions."
        },
        {
            image: proIntegration,
            featureName: "Mobile App Integration",
            description: "Attendance records can be integrated with a mobile app, allowing employees to check their attendance history and receive notifications."
        },
        {
            image: proRemote,
            featureName: "Remote Access",
            description: "The device can be accessed and managed remotely, allowing administrators to monitor attendance from anywhere."
        },
        {
            image: proStore,
            featureName: "Cloud-based Storage",
            description: "Attendance records are stored in the cloud, which can be accessed from anywhere and is secure."
        },

    ]

    return (
        <>
            <ImageSection
                title='Upo Attendance Pro'
                img={upoPro}
            />
            <div className='proFeaturesContainer'>
                <div className='container'>
                    <div className='titleSection'>
                        <h1>Upo Attendance Pro</h1>
                        <p>Our devices have Wifi, LAN and GPRS connectivity. They connect directly to our server and hence no need for local PC. Attendance/access data are updated within few moments.</p>
                    </div>
                    <div className='row'>
                        {data.map((data) =>
                        (
                            <div className='col-12 col-md-6 col-lg-4 mt-5 featureCard  '>
                                <div className='imgContainer'>
                                    <img height={90} src={data.image} />
                                </div>

                                <h5>{data.featureName}</h5>
                                <p>{data.description}</p>
                            </div>
                        )
                        )}

                    </div>
                </div>

            </div>

        </>
    )
}

export default UpoPro