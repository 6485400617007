import React from 'react'
import './About.css'
import underBar from '../../assets/underBar.png'

function About() {
    return (
        <section id='about'  >
            <div className='aboutBgContainer'>
                <div className='aboutBg'>
                    <div className='container d-flex align-items-center h-100'>
                        <div className='about'>
                            <h2>About Us</h2>
                            <img height={13} src={underBar} />
                            <p>Welcome to Upo, a technology company at the forefront of the Internet of Things (IoT) revolution. From our roots in attendance management solutions, we've grown to offer a wide range of innovative IoT devices that help businesses optimize their operations, streamline processes, and reduce costs.
                                Our flagship attendance management solutions, Upo Pro and Upo Lite, have become trusted industry standards, but we're just getting started. We're proud to offer a growing lineup of IoT devices that can help businesses of all sizes increase efficiency, improve productivity, and stay ahead of the competition.
                                We help businesses save money on energy costs, enhance security, and promote safer working environments. We're also working on solutions that integrate with existing business software and systems, enabling businesses to centralize their operations and gain more valuable insights into their performance.
                                At Upo, we're committed to providing our customers with the latest technology and the highest quality products. We believe that the IoT has the power to revolutionize the way businesses operate, and we're excited to be at the forefront of this innovation.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    )
}

export default About