import React, { useState } from 'react'
import Logo2 from '../../../assets/logo2.png'
import Logo from '../../../assets/logo.png'
import CallNow from '../../../assets/callNow.png'
import MenuBar from '../../../assets/menu.png'
import CloseBtn from '../../../assets/cross.png'
import { Link } from 'react-router-dom'
import './SideNav.css'
import SideNavContent from '../sideNavContent/SideNavContent'

function SideNav() {
    const [openMenu, setOpenMenu] = useState(false)

    return (
        <>
            <div className='sideNav'>
                <div className='container'>
                    <div className='Side_Nav d-flex justify-content-between align-items-center py-3'>

                        <Link to ='/' className='logo'>
                            <img src={Logo} height={25} />
                        </Link>

                        <button onClick={() => setOpenMenu(!openMenu)} className='MenuBar'>
                            <img src={MenuBar} height={15} />
                        </button>

                    </div>
                </div>
            </div>

            {openMenu ? (<div onClick={() => setOpenMenu(!openMenu)} className='sideNav_Content'>
                <div className='sideNav_bg'>

                    <div className='d-flex justify-content-between'>

                        <div className='logo mt-3'>
                            <img src={Logo2} height={30} />
                        </div>

                        <button onClick={() => setOpenMenu(!openMenu)} className="CloseBtn m-3">
                            <img src={CloseBtn} height={18} />
                        </button>
                    </div>

                    <SideNavContent />
                </div>
            </div>) : (<div onClick={() => setOpenMenu(!openMenu)} className='sideNav_Content_close'>
                <div className='sideNav_bg_close'>

                    <div className='d-flex justify-content-between'>

                        <div className='logo mt-3'>
                            <img src={Logo2} height={40} />
                        </div>

                        <button onClick={() => setOpenMenu(!openMenu)} className="CloseBtn m-3">
                            <img src={CloseBtn} height={22} />
                        </button>
                    </div>

                    <SideNavContent />
                </div>
            </div>)}
        </>
    )
}

export default SideNav