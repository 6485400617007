import React from 'react'
import './Input.css'
import submit from '../assets/submit.png'

function Input() {
    return (
        <div className='contactForm'>
            <div className='leaveMessage'>
                <h3>Leave a message</h3>
            </div>

            <div className='inputContainer'>
                <div className='labelContainer'>
                    <label>Name</label>
                </div>
                <input type="text" />
            </div>

            <div className='inputContainer'>
                <div className='labelContainer'>
                    <label>Number</label>
                </div>
                <input type="number" />
            </div>

            <div className='inputContainer'>
                <div className='labelContainer'>
                    <label>Email</label>
                </div>
                <input type="text" />
            </div>

            <div className='textAreaContainer'>
                <div className='labelContainer'>
                    <label>Message</label>
                </div>
                <textarea type="text" rows="4" cols="50" />
            </div>
            <div className='submit'>
                <button>
                    Submit
                    <img style={{ paddingLeft: '15px' }} height={17} src={submit} />
                </button>
            </div>

        </div>

    )
}

export default Input