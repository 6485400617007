import React from 'react'
import BannerImage from '../../assets/bannerImage.png'
import './Banner.css'

function Banner() {
    return (
        <>
            <section id='home'>
                <div className='Banner'>
                    <div className='container'>
                        <div className='row d-flex justify-content-center align-items-center py-5'>
                            <div className='col-md-12 col-lg-6 mt-5 mt-lg-0 pt-3 pt-lg-0 order-1 order-lg-0 '>
                                <div className='Content'>
                                    <h1>Upo Attendance Devices</h1>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                                    <a href='#contact'>Contact us</a>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-6 order-0 order-lg-1 d-flex justify-content-center'>
                                <img src={BannerImage} height={500} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner
