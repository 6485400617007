import React from 'react'
import './Use.css'
import factories from '../../assets/factories.png'
import health from '../../assets/health.png'
import institution from '../../assets/institution.png'
import shops from '../../assets/shops.png'
import office from '../../assets/office.png'
import events from '../../assets/events.png'
import underBar from '../../assets/underBar.png'

function Use() {
    const cardData = [
        {
            img: factories,
            title: 'Factories'
        },
        {
            img: institution,
            title: 'Educational Institution'
        },
        {
            img: health,
            title: 'Healthcare'
        },
        {
            img: events,
            title: 'Events'
        },
        {
            img: shops,
            title: 'Super Shops'
        },
        {
            img: office,
            title: 'Any Kind of Office'
        },
    ]
    return (
        <section id='use' >
            <div className='useCaseContainer ' style={{paddingTop:'120px'}} >
                <div className='container'>
                    <div className='usages'>
                        <h2>Our device is used in</h2>
                        <img height={13} src={underBar} />
                        <p>Revolutionize your workforce management with our reliable attendance system.</p>
                    </div>

                    <div className='row mt-4'>
                        {
                            cardData.map((data =>
                                <div className='col-12 col-md-6 col-lg-3 mt-4'>
                                    <div className='useCard'>
                                        <img height={70} src={data.img} />
                                        <h6>{data.title}</h6>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </section>

    )
}

export default Use