
import './App.css';
import Home from './pages/home/Home';
import MainRoute from './routes/MainRoute';

function App() {
  return (
    <div>
      <MainRoute/>
    </div>
  );
}

export default App;
