import React, { useState, useEffect } from 'react'
import CallNow from '../../../assets/callNow.png'
import './SideNavContent.css'

function SideNavContent() {

    const [onActive, setOnActive] = useState(' ');

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setOnActive(entry.target.id);
                    }
                });
            },
            { rootMargin: '-50% 0px -50% 0px' }
        );

        document.querySelectorAll('section[id]').forEach((section) => {
            observer.observe(section);
        });

        return () => {
            observer.disconnect();
        };
    }, []);


    return (
        <>
            <>
                <div className='SideNavContent'>
                    <div className='container'>
                        <nav>
                            <div className='NavItem'>
                                <div className='NavLink my-5'>
                                    <a href='#features' className='navLinks' onClick={() => setOnActive('features')}>
                                        {
                                            onActive === 'features' ? (<div className='ActiveColor'></div>) : null
                                        }
                                        <h6 className={onActive === 'features' ? 'Active' : ""}>FEATURES</h6>
                                    </a>
                                    <a href='#about' className='navLinks' onClick={() => setOnActive('about')}>
                                        {
                                            onActive === 'about' ? (<div className='ActiveColor'></div>) : null
                                        }
                                        <h6 className={onActive === 'about' ? 'Active' : ""}>ABOUT</h6>
                                    </a>

                                    <a href='#use' className='navLinks' onClick={() => setOnActive('useCase')}>
                                        {
                                            onActive === 'use' ? (<div className='ActiveColor'></div>) : null
                                        }
                                        <h6 className={onActive === 'use' ? 'Active' : ""}>USE</h6>
                                    </a>
                                    <a href='#support' className='navLinks' onClick={() => setOnActive('support')}>
                                        {
                                            onActive === 'support' ? (<div className='ActiveColor'></div>) : null
                                        }
                                        <h6 className={onActive === 'support' ? 'Active' : ""}>SUPPORT</h6>
                                    </a>
                                    <a href='#contact' className='navLinks me-3' onClick={() => setOnActive('contact')}>
                                        {
                                            onActive === 'contact' ? (<div className='ActiveColor'></div>) : null
                                        }
                                        <h6 className={onActive === 'contact' ? 'Active' : ""}>CONTACT</h6>
                                    </a>

                                </div>
                            </div>

                            <div className='CallNow'>
                                <div className='Content_bg'>
                                    <div className='Contact_Now'>
                                        <img src={CallNow} height={20} />
                                        <div className='Number'>
                                            <h6>01558108600</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </>
        </>
    )
}

export default SideNavContent



// import React, {useState} from 'react'
//             import CallNow from '../../../assets/callNow.png'
//             import './SideNavContent.css'

//             function SideNavContent() {

//     const [onActive, setOnActive] = useState(' ');
//             return (
//             <>
//                 <div className='SideNavContent'>
//                     <div className='container'>
//                         <nav>
//                             <div className='NavItem'>
//                                 <div className='NavLink my-5'>
//                                     <a href='#features' className='navLinks' onClick={() => setOnActive('features')}>
//                                         {
//                                             onActive === 'features' ? (<div className='ActiveColor'></div>) : null
//                                         }
//                                         <h6 className={onActive === 'features' ? 'Active' : ""}>FEATURES</h6>
//                                     </a>
//                                     <a href='#about' className='navLinks' onClick={() => setOnActive('about')}>
//                                         {
//                                             onActive === 'about' ? (<div className='ActiveColor'></div>) : null
//                                         }
//                                         <h6 className={onActive === 'about' ? 'Active' : ""}>ABOUT</h6>
//                                     </a>

//                                     <a href='#use' className='navLinks' onClick={() => setOnActive('use')}>
//                                         {
//                                             onActive === 'use' ? (<div className='ActiveColor'></div>) : null
//                                         }
//                                         <h6 className={onActive === 'use' ? 'Active' : ""}>USE</h6>
//                                     </a>
//                                     <a href='#support' className='navLinks' onClick={() => setOnActive('support')}>
//                                         {
//                                             onActive === 'support' ? (<div className='ActiveColor'></div>) : null
//                                         }
//                                         <h6 className={onActive === 'support' ? 'Active' : ""}>SUPPORT</h6>
//                                     </a>
//                                     <a href='#contact' className='navLinks me-3' onClick={() => setOnActive('contact')}>
//                                         {
//                                             onActive === 'contact' ? (<div className='ActiveColor'></div>) : null
//                                         }
//                                         <h6 className={onActive === 'contact' ? 'Active' : ""}>CONTACT</h6>
//                                     </a>
//                                 </div>
//                             </div>


//                         </nav>
//                     </div>
//                 </div>
//             </>
//             )
// }

//             export default SideNavContent