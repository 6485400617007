import React from 'react'
import underBar from '../../../assets/underBar.png'
import './ImageSection.css'

function ImageSection(props) {
    return (
        <div className='imgSectionContainer'>
            <div className='container'>
                <div className='feature'>
                    <h5>FEATURE</h5>
                    <img height={13} src={underBar} />
                </div>
                <div className='featureImg mt-4'>
                    <h1>{props.title}</h1>
                    <div className='imgContainer my-5 pt-5'>
                        <img src={props.img} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default ImageSection