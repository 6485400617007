import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from 'react'
import Home from '../pages/home/Home'
import TopNav from '../component/topNav/TopNav'
import Nav from '../component/nav/Nav'



function MainRoute() {

    return (
        <div>
            <Router>
                <TopNav/>
                <Routes>
                    <Route path="/" element={<Home/>} />
                </Routes>
            </Router>
        </div>
    )
}

export default MainRoute