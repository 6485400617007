
import React, { useState, useEffect } from 'react'
import './TopNav.css'
import Logo2 from '../../assets/logo.png'
import ActiveIcon from '../../assets/active.png'
import CallNow from '../../assets/callNow.png'
import SideNav from './sideNav/SideNav'

function TopNav() {
    const [onActive, setOnActive] = useState(' ');
    
  useEffect(() => {
    const handleScroll = () => {
      const featuresSection = document.querySelector('#features');
      const aboutSection = document.querySelector('#about');
      const useSection = document.querySelector('#use');
      const supportSection = document.querySelector('#support');
      const contactSection = document.querySelector('#contact');

      if (
        window.scrollY < featuresSection.offsetTop - 50 ||
        window.scrollY >= contactSection.offsetTop + contactSection.offsetHeight - 50
      ) {
        setOnActive(' ');
      } 
       else if (
        window.scrollY >= featuresSection.offsetTop - 50 &&
        window.scrollY < aboutSection.offsetTop - 50
      ) {
        setOnActive('features');
      } else if (
        window.scrollY >= aboutSection.offsetTop - 50 &&
        window.scrollY < useSection.offsetTop - 50
      ) {
        setOnActive('about');
      } else if (
        window.scrollY >= useSection.offsetTop - 50 &&
        window.scrollY < supportSection.offsetTop - 50
      ) {
        setOnActive('use');
      } else if (window.scrollY >= supportSection.offsetTop - 50 && window.scrollY < contactSection.offsetTop - 50) {
        setOnActive('support');
      } else if (window.scrollY >= contactSection.offsetTop - 50) {
        setOnActive('contact');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
    return (
        <>
            <div className='Navbar'>
                <div className='container'>
                    <nav>
                        <a href='#home' className='logo'>
                            <img src={Logo2} height={40} />
                        </a>

                        <div className='NavItem'>
                            <div className='NavLink'>
                                {/* <a
                                    href='#home'
                                    className={`navLinks${onActive === 'home' ? ' active' : ''}`}
                                    onClick={() => setOnActive( 'home')}
                                >
                                    <h6>Home</h6>
                                    {onActive === 'home' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a> */}
                                <a
                                    href='#features'
                                    className={`navLinks${onActive === 'features' ? ' active' : ''}`}
                                    onClick={() => setOnActive('features')}
                                >
                                    <h6>Features</h6>
                                    {onActive === 'features' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a>
                                <a
                                    href='#about'
                                    className={`navLinks me-3${onActive === 'about' ? ' active' : ''}`}
                                    onClick={() => setOnActive('about')}
                                >
                                    <h6>About</h6>
                                    {onActive === 'about' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a>
                                <a
                                    href='#use'
                                    className={`navLinks${onActive === 'use' ? ' active' : ''}`}
                                    onClick={() => setOnActive( 'use')}
                                >
                                    <h6>Use</h6>
                                    {onActive === 'use' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a>

                                <a
                                    href='#support'
                                    className={`navLinks${onActive === 'support' ? ' active' : ''}`}
                                    onClick={() => setOnActive('support')}
                                >
                                    <h6>Support</h6>
                                    {onActive === 'support' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a>
                                <a
                                    href='#contact'
                                    className={`navLinks${onActive === 'contact' ? ' active' : ''}`}
                                    onClick={() => setOnActive('contact')}
                                >
                                    <h6>Contact</h6>
                                    {onActive === 'contact' ? (
                                        <div className='d-flex justify-content-center'>
                                            <img src={ActiveIcon} height={3} />
                                        </div>
                                    ) : null}
                                </a>
                            </div>
                        </div>
                        <div className='Contact_Now'>
                            <img src={CallNow} height={20} />
                            <div className='Number'>
                                <h6>01558108600</h6>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <SideNav />

        </>
    )
}

export default TopNav