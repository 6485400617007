import React from 'react'
import './Support.css'
import SupportImage from '../../assets/support.png'

function Support() {
    return (
        <>
            <section id='support'>
                <div className='DailyProduction py-0 py-md-5'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-6 order-1 order-lg-0   DailyProductionContent'>
                            <div className='Info'>
                                <h2>1 YEAR FULLY <br /> REPLACEMENT WARRANTY</h2>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6 text-center px-0 order-0 order-lg-1'>
                            <div className='DailyProductionImage'>
                                <img src={SupportImage} height={100} />
                                <h3>LIFETIME SERVICE WARRANTY</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Support
