import React from 'react'
import ImageSection from '../featureImageSection/ImageSection'
import upoLite from '../../../assets/upoLite.png'
import liteIntegration from '../../../assets/liteIntegration.png'
import liteRemote from '../../../assets/liteRemote.png'
import liteReport from '../../../assets/liteReport.png'
import lowCost from '../../../assets/lowCost.png'
import liteStore from '../../../assets/liteStore.png'
import './UpoLite.css'

function UpoLite() {


    const data = [
        {
            image: lowCost,
            featureName: "Super Affordable cost",
            description: "The device can provide analytics and reports on attendance patterns, helping organizations make data-driven decisions."
        },
        {
            image: liteIntegration,
            featureName: "Mobile App Integration",
            description: "Attendance records can be integrated with a mobile app, allowing employees to check their attendance history and receive notifications."
        },
        {
            image: liteRemote,
            featureName: "Remote Access",
            description: "The device can be accessed and managed remotely, allowing administrators to monitor attendance from anywhere."
        },

        {
            image: liteStore,
            featureName: "Cloud-based Storage",
            description: "Attendance records are stored in the cloud, which can be accessed from anywhere and is secure."
        },
        {
            image: liteReport,
            featureName: "Analytics and Reporting",
            description: "The device can provide analytics and reports on attendance patterns, helping organizations make data-driven decisions."
        },

    ]

    return (
        <>
            <ImageSection
                title='Upo Attendance lite'
                img={upoLite}
            />
            <div className='liteFeaturesContainer'>
                <div className='container'>
                    <div className='titleSection'>
                        <h1>Upo Attendance Lite</h1>
                        <p>Our devices have Wifi, LAN and GPRS connectivity. They connect directly to our server and hence no need for local PC. Attendance/access data are updated within few moments.</p>
                    </div>
                    <div className='row'>
                        {data.map((data) =>
                        (
                            <div className='col-12 col-md-6 col-lg-4 mt-5 featureCard  '>
                                <div className='liteFeaturesBg'>
                                    <div className='imgContainer'>
                                        <img height={90} src={data.image} />
                                    </div>

                                    <h5>{data.featureName}</h5>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        )
                        )}

                    </div>
                </div>

            </div>

        </>
    )
}

export default UpoLite