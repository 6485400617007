import React from 'react'
import UnderBar from '../../assets/underBar.png'
import Facebook from '../../assets/facebook.png'
import Linkdin from '../../assets/linkdin.png'
import Youtube from '../../assets/youtube.png'
import Mail from '../../assets/email.png'
import Location from '../../assets/location.png'
import callNow from '../../assets/call.png'
import './ContactInfo.css'
import Input from '../../input/Input'
import { Link } from 'react-router-dom'

function ContactInfo() {
    return (
        <section id='contact'>
            <div className='ContactInfo'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-6 px-0'>
                            <div className='info text-center'>
                                <h2>Get In Touch With Us</h2>
                                <img src={UnderBar} height={15} />
                                <div className='infoItem'>
                                    <div className='d-flex align-items-center mt-4'>
                                        <img src={Mail} height={23} />
                                        <h6 className='m-0 px-4'>upodevices@gmail.com</h6>
                                    </div>
                                    <div className='d-flex align-items-center mt-4'>
                                        <img src={callNow} height={24} />
                                        <h6 className='m-0 px-4'>+8801558108600</h6>
                                    </div>
                                    <div className='d-flex align-items-center text-start mt-4'>
                                        <img src={Location} height={24} />
                                        <h6 className='m-0 px-4'>Level 04,Awal Center,34 Kemal Ataturk avenue, Banani, Dhaka-1213.</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-start align-items-center mt-5 pt-5'>
                                    <a href='https://www.facebook.com/profile.php?id=100090316634822' target="_blank">
                                        <img src={Facebook} height={45} />
                                    </a>
                                    <Link to='/linkedin'>
                                        <img className='ms-4' src={Linkdin} height={45} />
                                    </Link>
                                    <Link to='/youtube'>
                                        <img className='ms-4' src={Youtube} height={45} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div style={{ background: '#fff' }} className='col-md-12 col-lg-6 px-0'>
                            <div className='contactForm'>
                                <Input />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactInfo